import React from 'react';
import Navbar from '../components/Navbar';
import HeroImg from "../components/HeroImg";
import Footer from "../components/Footer";

const Home = () => {
  return ( 
  <main>
    <Navbar />
    <HeroImg />
    <Footer />
  </main>
  );
};

export default Home;